import React, { useEffect, useState } from "react";
import { Button, Checkbox, Form, Input } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import yaml from "yaml";
import { saveAs } from "file-saver";
import axios from "axios";
import Quiz from "./quiz";
import Score from "./score";
import { parse } from "path";

const questions = [
    "Welk kleur van ogen heeft de mol?",
    "Welke schoenmaat heeft de mol?",
    "Hoeveel kinderen heeft de mol?",
    "Heeft de mol deelgenomen aan de sportdag?",
    "Was de mol aanwezig op het EARMA congres?",
    "Welk broodje heeft de mol deze middag gegeten?",
    "Welke hobby heeft de mol?",
    "Hoe handig is de mol?",
    "Waar woont de mol?",
    "In welke maand van het jaar is de mol geboren?",
    "Welk lievelingskleur heeft de mol?",
    "Hoe komt de mol het meest naar het werk?",
    "Welke rol heeft de mol in DOZA?",
    "Wat is de leeftijd van de mol?",
    "Wat is de leeftijd van de mol?",
    "Wat is de naam van de mol?"
]

const solutions = [
    "Bruin",
    "39",
    "2",
    "Nee",
    "Ja",
    "Jeanine",
    "Haar knippen",
    "Rechtshandig",
    "Sint-Amandsberg",
    "Oktober",
    "Roze",
    "Fiets",
    "DOZA partner",
    "tussen de 35 en 40",
    "Jozefien"
]

interface Quiz {
    questions: Question[];
    solutions: string[];
}

interface Question {
    question: string;
    answers: string[];
}

interface AnswerSheet {
    name: string;
    date: string;
    time: number;
    answers: string[];
}

interface Answer {
    question: string;
    guess: string;
    solution: string;
}

// const quizex: Quiz = {answers:[""], questions: [{ question: "What is the capital of France?", answers: ["Paris", "London", "Berlin"]}, { question: "Who painted the Mona Lisa?", answers: ["Leonardo da Vinci", "Vincent van Gogh", "Pablo Picasso", "Claude Monet"] }, { question: "Which planet is known as the Red Planet?", answers: ["Mars", "Jupiter", "Venus", "Mercury", "Saturn", "Earth", "Uranus", "Neptune"] }, { question: "Who wrote the novel 'Pride and Prejudice'?", answers: ["Jane Austen", "Charlotte Brontë", "Emily Brontë", "William Shakespeare", "Charles Dickens", "F. Scott Fitzgerald"]}, { question: "What is the largest ocean in the world?", answers: ["Pacific Ocean", "Atlantic Ocean", "Indian Ocean", "Southern Ocean", "Arctic Ocean", "Antarctic Ocean"]}] };


function calcScore(data: string[]) {
    var res = 0;
    for (var i = 0; i < data.length; i++) {
        if (data[i] === solutions[i]) {
            res++
        }
    }
    return res
}



const App = () => {
    const [quiz, setQuiz] = React.useState<AnswerSheet[]>([]);

    React.useEffect(() => {
        const fetchData = () => {
            axios.get("https://mol-clone.onrender.com/answers/-O0QH6QPabiKx1p6BMI8")
                .then(response => {
                    setQuiz(response.data.data);
                    console.log(response.data.data[0].name);
                });
        };

        fetchData(); // Load data initially

        const interval = setInterval(fetchData, 5000); // Fetch data at intervals

        return () => clearInterval(interval); // Cleanup on component unmount
    }, []);

    return (
        <div style={{ display: 'flex', backgroundColor: 'black', flex: 1, height: '100%', flexDirection: "column" }}>
            <div
                style={{
                    display: "flex",
                    alignContent: "center",
                    justifyContent: "center",
                    alignSelf: "center",
                    width: "100%",
                    height: "100vh",
                    backgroundColor: "black",
                }}
            >
                <div
                    style={{
                        textAlign: "center",
                        width: "100%",
                        height: "100%",
                        paddingTop: 100,
                        backgroundColor: 'black'
                    }}
                >
                    <img src="mol.png" style={{ width: "75%" }} />
                    <h1 style={{ color: "white", fontSize: 56, marginBottom: 20 }}>
                        Wie is de mol?
                    </h1>
                    <h3 style={{ color: "#0B5345", fontSize: 36, marginBottom: -20 }}>
                        DOZA
                    </h3>
                    <h3 style={{ color: "#0B5345", fontSize: 36, marginBottom: -20 }}>
                        Teamdag
                    </h3>
                    <h3 style={{ color: "#0B5345", fontSize: 36, marginBottom: -20 }}>
                        2024
                    </h3>
                    <div style={{ height: "10%" }}></div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                        {quiz.reverse().map((answers, i) => (
                            <div style={{ marginBottom: 5, backgroundColor: 'black' }}>
                                <Answers data={answers} i={i} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
};



export default App;


function Answers({ data, i }: { data: AnswerSheet, i: number }) {
    const [open, setOpen] = React.useState(false)

    return (
        <div style={{ borderWidth: 2, borderRadius: 10, borderColor: 'white', backgroundColor: 'black', borderStyle: 'solid', width: '100%' }} onClick={() => { setOpen(!open) }}>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <div>
                    <h2 style={{ color: 'white' }}>{data.name}</h2>
                </div>
                <div>
                    <h2 style={{ color: 'white' }}>Score: {calcScore(data.answers)}</h2>
                </div>
                <div>
                    <h2 style={{ color: 'white' }}>{data.time} seconden</h2>
                </div>
                <div>
                    <h2 style={{ color: 'white' }}>{data.date.slice(15, 25)}</h2>
                </div>
            </div>
            {open &&
                (data.answers).map((answer, i) => (
                    <div style={{ display: 'flex', justifyContent: '', color: 'white', fontSize: 17, marginBottom: 10 }}>
                        <div style={{ width: '70%', justifyContent: 'left', display: 'flex' }}>
                            {i + 1}: {questions[i]}
                        </div>
                        <div style={{ alignItems: 'left', display: "flex", width: '30%' }}>
                            {answer}({solutions[i]})
                        </div>
                    </div>
                ))
            }
        </div>)

}
